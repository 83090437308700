import { changeType,walletStyle,relationType} from "@/utils/record"
export const topUp = {
    border: true,
    menuAlign: 'center',
    align: 'center',
    height: 'auto',
    refreshBtn: false,
    showClomnuBtn: false,
    searchMenuSpan: 6,
    searchLabelWidth: '130',
    labelWidth: '150',
    columnBtn: false,
    addBtn: false,
    editBtn: false,
    delBtn: false,
    menu:false,
    column: [{
        width: 200,
        label: 'ID',
        prop: 'recordId',
    },{
        width: 100,
        label: '商户',
        prop: 'tenantName',
    }, {
        width: 100,
        label: 'App名称',
        prop: 'appName',
    }, {
        width:100,
        label: '链',
        prop: 'chain'
    }, {
        width:120,
        label: '协议',
        prop: 'protocol'
    },{
        width:120,
        label: '合约地址',
        prop: 'contractAddress'
    },{
        width:120,
        label: '钱包名称',
        prop: 'walletName'
    },{
        width:200,
        label: '钱包地址',
        prop: 'walletAddress'
    },{
        width:120,
        label: '钱包类型',
        prop: 'walletType',
        dicData: walletStyle,
    },{
        width:200,
        label: 'From',
        prop: 'fromAddress'
    },{
        width:300,
        label: 'TXID',
        prop: 'txid'
    },{
        width:100,
        label: '数量',
        prop: 'amount'
    },{
        width:120,
        label: '系列名称',
        prop: 'collectionName'
    },{
        width:100,
        label: '作品名称',
        prop: 'itemName'
    },{
        width:200,
        label: 'TokenId',
        prop: 'tokenId'
    },{
        width:200,
        label: '链费',
        prop: 'txFee'
    },{
        width:180,
        label: '交易时间',
        prop: 'confirmTime'
    },{
        width:180,
        label: '创建时间',
        prop: 'createTime'
    },]
}
export const withdrawCash = {
    border: true,
    menuAlign: 'center',
    align: 'center',
    height: 'auto',
    refreshBtn: false,
    showClomnuBtn: false,
    searchMenuSpan: 6,
    searchLabelWidth: '130',
    labelWidth: '150',
    columnBtn: false,
    addBtn: false,
    editBtn: false,
    delBtn: false,
    menu:false,
    column: [{
        width: 120,
        label: 'ID',
        prop: 'applyId',
    },{
        width: 300,
        label: '交易ID',
        prop: 'transactionId',
    },{
        width:100,
        label: '审核状态',
        prop: 'status',
        dicData: [{ label: '待审核', value: 0 },{label: '审核通过',value:1},{label: '审核拒绝',value:2}],
    },{
        width: 100,
        label: '商户',
        prop: 'tenantName',
    }, {
        width: 100,
        label: 'App名称',
        prop: 'appName',
    }, {
        width:100,
        label: '链',
        prop: 'chain'
    }, {
        width:120,
        label: '协议',
        prop: 'protocol'
    },{
        width:200,
        label: '合约地址',
        prop: 'contractAddress'
    },{
        width:200,
        label: '商户地址',
        prop: 'fromAddress'
    },{
        width:120,
        label: '钱包名称',
        prop: 'fromWalletName'
    },{
        width:120,
        label: '钱包类型',
        prop: 'fromWalletType',
        dicData: walletStyle,
    },{
        width:200,
        label: 'To',
        prop: 'toAddress'
    },{
        width:100,
        label: '状态',
        prop: 'withdrawStatus',
        dicData: [{ label: '未处理', value: 0 },{label: '待确认',value:1},{label: '已确认',value:2},{label: '确认失败',value:3}],
    },{
        width:180,
        label: '交易时间',
        prop: 'withdrawTime'
    },{
        width:300,
        label: 'TXID',
        prop: 'txid'
    },{
        width:100,
        label: '数量',
        prop: 'amount'
    },{
        width:200,
        label: '系列名称',
        prop: 'collectionName'
    },{
        width:200,
        label: '作品名称',
        prop: 'itemName'
    },{
        width:200,
        label: 'TokenId',
        prop: 'tokenId'
    },{
        width:100,
        label: '链费',
        prop: 'txFee'
    },{
        width:160,
        label: '创建时间',
        prop: 'withdrawTime'
    },]
}
export const aggregation = {
    border: true,
    menuAlign: 'center',
    align: 'center',
    height: 'auto',
    refreshBtn: false,
    showClomnuBtn: false,
    searchMenuSpan: 6,
    searchLabelWidth: '130',
    labelWidth: '150',
    columnBtn: false,
    addBtn: false,
    editBtn: false,
    delBtn: false,
    menu:false,
    column: [{
        width: 200,
        label: 'ID',
        prop: 'collectionRecordId',
    },{
        width: 100,
        label: '商户',
        prop: 'tenantName',
    }, {
        width: 100,
        label: 'App名称',
        prop: 'appName',
    }, {
        width:100,
        label: '链',
        prop: 'chain'
    }, {
        width:120,
        label: '协议',
        prop: 'protocol'
    },{
        width:200,
        label: '合约地址',
        prop: 'contractAddress'
    },{
        width:200,
        label: '商户地址',
        prop: 'toAddress'
    },{
        width:120,
        label: '钱包名称',
        prop: 'toWalletName'
    },{
        width:120,
        label: '钱包类型',
        prop: 'toWalletType',
        dicData: walletStyle,
    },{
        width:200,
        label: '客户ID',
        prop: 'userId'
    },{
        width:200,
        label: '客户地址',
        prop: 'fromAddress'
    },{
        width:300,
        label: 'TXID',
        prop: 'txid'
    },{
        width:100,
        label: '数量',
        prop: 'qty'
    },{
        width:120,
        label: '系列名称',
        prop: 'collectionName'
    },{
        width:200,
        label: '作品名称',
        prop: 'itemName'
    },{
        width:200,
        label: 'TokenId',
        prop: 'tokenId'
    },{
        width:100,
        label: '状态',
        prop: 'status',
        dicData: [{ label: '未处理', value: 0 },{label: '待确认',value:1},{label: '已确认',value:2},{label: '确认失败',value:3}],
    },{
        width:100,
        label: '链费',
        prop: 'txFee'
    },{
        width:160,
        label: '交易时间',
        prop: 'confirmTime'
    },{
        width:160,
        label: '创建时间',
        prop: 'createTime'
    },]
}
export const walletWater = {
    border: true,
    menuAlign: 'center',
    align: 'center',
    height: 'auto',
    refreshBtn: false,
    showClomnuBtn: false,
    searchMenuSpan: 6,
    searchLabelWidth: '130',
    labelWidth: '150',
    columnBtn: false,
    addBtn: false,
    editBtn: false,
    delBtn: false,
    menu:false,
    column: [{
        width: 200,
        label: '变动ID',
        prop: 'changeLogId',
    },{
        width: 100,
        label: '商户',
        prop: 'tenantName',
    }, {
        width: 100,
        label: 'App名称',
        prop: 'appName',
    }, {
        width:100,
        label: '链',
        prop: 'chain'
    }, {
        width:120,
        label: '协议',
        prop: 'protocol'
    },{
        width:120,
        label: '合约地址',
        prop: 'contractAddress'
    },{
        width:120,
        label: '钱包名称',
        prop: 'walletName'
    },{
        width:120,
        label: '钱包类型',
        prop: 'walletType',
        dicData: walletStyle,
    }, {
        width:120,
        label: '商户地址',
        prop: 'toAddress'
    },{
        width:200,
        label: 'From',
        prop: 'fromAddress'
    },{
        width:200,
        label: 'To',
        prop: 'toAddress'
    },{
        width:120,
        label: '系列名称',
        prop: 'collectionName'
    },{
        width:100,
        label: '作品名称',
        prop: 'itemName'
    },{
        width:200,
        label: 'TokenId',
        prop: 'tokenId'
    },{
        width:120,
        label: '币种',
        prop: 'coin'
    },{
        width:300,
        label: 'TXID',
        prop: 'txid'
    },{
        width:100,
        label: '变动前数量',
        prop: 'beforeAmount'
    },{
        width:200,
        label: '变动数量',
        prop: 'amount'
    },{
        width:180,
        label: '变动后数量',
        prop: 'afterAmount'
    },{
        width:180,
        label: '操作类型',
        prop: 'changeType',
        dicData: changeType,
    },{
        width:180,
        label: '关联订单号',
        prop: 'relationId'
    },{
        width:180,
        label: '业务类型',
        prop: 'relationType',
        dicData: relationType,
    },
    ]
}
export const accountWater = {
    border: true,
    menuAlign: 'center',
    align: 'center',
    height: 'auto',
    refreshBtn: false,
    showClomnuBtn: false,
    searchMenuSpan: 6,
    searchLabelWidth: '130',
    labelWidth: '150',
    columnBtn: false,
    addBtn: false,
    editBtn: false,
    delBtn: false,
    menu:false,
    column: [{
        width: 180,
        label: '变动ID',
        prop: 'changeLogId',
    },{
        width: 200,
        label: '商户',
        prop: 'tenantName',
    }, {
        width: 100,
        label: 'App名称',
        prop: 'appName',
    }, {
        width:100,
        label: '链',
        prop: 'chain'
    }, {
        width:120,
        label: '协议',
        prop: 'protocol'
    },{
        width:140,
        label: '作品名称',
        prop: 'collectionName'
    },{
        width:120,
        label: '系列名称',
        prop: 'collectionName'
    },{
        width: 100,
        label: 'TokenId',
        prop: 'tokenId'
    },{
        width:200,
        label: '合约地址',
        prop: 'contractAddress'
    },{
        width:120,
        label: '币种',
        prop: 'coin'
    },{
        width:100,
        label: '变动前数量',
        prop: 'beforeAmount'
    },{
        width:200,
        label: '变动数量',
        prop: 'amount'
    },{
        width:180,
        label: '变动后数量',
        prop: 'afterAmount'
    },{
        width:100,
        label: '操作类型',
        prop: 'changeType',
        dicData: changeType,
    },{
        width:180,
        label: '关联订单号',
        prop: 'relationId'
    },{
        width: 100,
        label: '业务类型',
        prop: 'relationType',
        dicData: [{ label: '充值记录', value: 1 },{label: '提现记录',value:2},{label: '链费记录',value:3},{label: '暂无',value:0}],
    },{
        width:170,
        label: '变动时间',
        prop: 'createTime'
    },{
        width:180,
        label: '操作人',
        prop: 'createBy'
    },]
}
export const balanceWallet = {
    border: true,
    menuAlign: 'center',
    align: 'center',
    height: 'auto',
    refreshBtn: false,
    showClomnuBtn: false,
    searchMenuSpan: 6,
    searchLabelWidth: '130',
    labelWidth: '150',
    columnBtn: false,
    addBtn: false,
    editBtn: false,
    delBtn: false,
    menu:false,
    column: [{
        width: 100,
        label: '商户',
        prop: 'tenantName',
    }, {
        width: 100,
        label: 'App名称',
        prop: 'appName',
    }, {
        width:100,
        label: '链',
        prop: 'chain'
    }, {
        width:120,
        label: '协议',
        prop: 'protocol'
    },{
        width:120,
        label: '钱包名称',
        prop: 'walletName'
    },{
        width:120,
        label: '钱包类型',
        prop: 'walletType',
        dicData: walletStyle,
    },{
        label: '钱包地址',
        prop: 'walletAddress'
    },{
        width:200,
        label: '当前系列数量',
        prop: 'collectionNum'
    },{
        width:120,
        label: '当前作品数量',
        prop: 'nftNum'
    },{
        width:160,
        label: '更新时间',
        prop: 'updateTime'
    }]
}
export const balanceAccount = {
    border: true,
    menuAlign: 'center',
    align: 'center',
    height: 'auto',
    refreshBtn: false,
    showClomnuBtn: false,
    searchMenuSpan: 6,
    searchLabelWidth: '130',
    labelWidth: '150',
    columnBtn: false,
    addBtn: false,
    editBtn: false,
    delBtn: false,
    menu:false,
    column: [{
        label: '商户',
        prop: 'tenantName',
    }, {
        label: 'App名称',
        prop: 'appName',
    }, {
        width:100,
        label: '链',
        prop: 'chain'
    }, {
        width:120,
        label: '协议',
        prop: 'protocol'
    },{
        width:120,
        label: '当前系列数量',
        prop: 'collectionNum'
    },{
        width:120,
        label: '当前作品数量',
        prop: 'nftNum'
    },{
        width:180,
        label: '更新时间',
        prop: 'updateTime'
    },]
}
export const nftSeries = {
    border: true,
    menuAlign: 'center',
    align: 'center',
    height: 'auto',
    refreshBtn: false,
    showClomnuBtn: false,
    searchMenuSpan: 6,
    searchLabelWidth: '130',
    labelWidth: '150',
    columnBtn: false,
    addBtn: false,
    editBtn: false,
    delBtn: false,
    menu:false,
    column: [{
        width: 100,
        label: '商户',
        prop: 'tenantName',
    }, {
        width: 100,
        label: 'App名称',
        prop: 'appName',
    }, {
        width:100,
        label: '链',
        prop: 'chain'
    }, {
        width:120,
        label: '协议',
        prop: 'protocol'
    },{
        width:200,
        label: '合约地址',
        prop: 'contractAddress'
    },{
        width:120,
        label: '系列名称',
        prop: 'collectionName'
    },{
        width:120,
        label: '作品最大数量',
        prop: 'maxItemQty'
    },{
        width:300,
        label: 'TXID',
        prop: 'txid'
    },{
        width:200,
        label: '创造者地址',
        prop: 'creator'
    },{
        width:200,
        label: '链费',
        prop: 'txFee'
    },{
        width:120,
        label: 'mint权限',
        prop: 'mintType',
        dicData: [{ label: 'owner', value: 1 },{label: '允许任何人',value:2}],
    },{
        width:120,
        label: '链费状态',
        prop: 'mintFeeStatus',
        dicData: [{ label: '未打链费', value: 0 },{label: '已打链费',value:1},{label: '链费充足',value:2},{label: '链费处理中',value:4}],
    },{
        width:120,
        label: '状态',
        prop: 'status',
        dicData: [{ label: '提交中', value: 0 },{ label: '待确认', value: 1 },{label: '已确认',value:2},{label: '确认失败',value:3}],
    },{
        width:320,
        label: '图片Url',
        prop: 'collectionImg'
    },{
        width:180,
        label: '更新时间',
        prop: 'updateTime'
    },{
        width:180,
        label: '创建时间',
        prop: 'createTime'
    },]
}
export const nftWorks = {
    border: true,
    menuAlign: 'center',
    align: 'center',
    height: 'auto',
    refreshBtn: false,
    showClomnuBtn: false,
    searchMenuSpan: 6,
    searchLabelWidth: '130',
    labelWidth: '150',
    columnBtn: false,
    addBtn: false,
    editBtn: false,
    delBtn: false,
    menu:false,
    column: [{
        width: 100,
        label: '商户',
        prop: 'tenantName',
    }, {
        width: 100,
        label: 'App名称',
        prop: 'appName',
    }, {
        width:100,
        label: '链',
        prop: 'chain'
    }, {
        width:120,
        label: '协议',
        prop: 'protocol'
    },{
        width:200,
        label: '合约地址',
        prop: 'contractAddress'
    },{
        width:120,
        label: '系列名称',
        prop: 'collectionName'
    },{
        width:120,
        label: '作品名称',
        prop: 'itemName'
    },{
        width:200,
        label: '数量',
        prop: 'qty'
    },{
        width:200,
        label: 'TokenId',
        prop: 'tokenId'
    },{
        width:300,
        label: 'TXID',
        prop: 'txid'
    },{
        width:200,
        label: '链上创建者地址',
        prop: 'mintAddr'
    },{
        width:120,
        label: '持有者类型',
        prop: 'mintorType',
        dicData: [{ label: '客户', value: 1 },{label: '商户',value:2}],
    },{
        width:120,
        label: '链费状态',
        prop: 'mintFeeStatus',
        dicData: [{ label: '未打链费', value: 0 },{label: '已打链费',value:1},{label: '链费充足',value:2},{label: '链费处理中',value:4}],
    },{
        width:120,
        label: '状态',
        prop: 'status',
        dicData: [{ label: '提交中', value: 0 },{ label: '待确认', value: 1 },{label: '已确认',value:2},{label: '确认失败',value:3}],
    },{
        width:320,
        label: '作品URL',
        prop: 'itemUrl'
    },{
        width:320,
        label: '元数据URL',
        prop: 'metadataUrl'
    },{
        width:180,
        label: '更新时间',
        prop: 'updateTime'
    },{
        width:180,
        label: '创建时间',
        prop: 'createTime'
    },]
}