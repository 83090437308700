import { statusType,walletStyle,changeType } from "@/utils/record"
import { fromSearchFore,contractAddress } from '@/const/publicData/record'
export const topUp_ = [{
    type: 'input',
    name: 'ID',
    prop: 'recordId',
},
    ...fromSearchFore,
    ...contractAddress,
{
    type: 'input',
    name: '钱包地址',
    prop: 'walletAddress',
},{
    type: 'input',
    name: 'From',
    prop: 'fromAddress',
},{
    type: 'input',
    name: 'TXID',
    prop: 'txid',
},{
    type: 'input',
    name: '系列名称',
    prop: 'collectionName',
},{
    type: 'input',
    name: 'NFT名称',
    prop: 'itemName',
},{
    type: 'input',
    name: 'TokenId',
    prop: 'tokenId',
},{
    type: 'datetime',
    name: '交易时间',
    prop: 'confirmTime',
},{
    type: 'datetime',
    name: '创建时间',
    prop: 'createTime',
},]
export const withdrawCash_ = [{
    type: 'input',
    name: 'ID',
    prop: 'applyId',
},{
    type: 'select',
    name: '审核状态',
    prop: 'status',
    postData: [{ label: '待审核', value: 0 },{label: '审核通过',value:1},{label: '审核拒绝',value:2}],
},
    ...fromSearchFore,
    ...contractAddress,
{
    type: 'input',
    name: '商户地址',
    prop: 'fromAddress',
},{
    type: 'input',
    name: 'To',
    prop: 'toAddress',
},{
    type: 'input',
    name: 'TXID',
    prop: 'txid',
},{
    type: 'input',
    name: '系列名称',
    prop: 'collectionName',
},{
    type: 'input',
    name: 'NFT名称',
    prop: 'itemName',
},{
    type: 'input',
    name: 'TokenId',
    prop: 'tokenId',
},{
    type: 'datetime',
    name: '交易时间',
    prop: 'confirmTime',
},{
    type: 'datetime',
    name: '创建时间',
    prop: 'applyTime',
},]
export const aggregation_ = [
    ...fromSearchFore,
    ...contractAddress,
{
    type: 'input',
    name: '商户地址',
    prop: 'toAddress',
},{
    type: 'input',
    name: '客户ID',
    prop: 'userId',
},{
    type: 'input',
    name: '客户地址',
    prop: 'fromAddress',
},{
    type: 'input',
    name: 'TXID',
    prop: 'txid',
},{
    type: 'input',
    name: '系列名称',
    prop: 'collectionName',
},{
    type: 'input',
    name: 'NFT名称',
    prop: 'itemName',
},{
    type: 'input',
    name: 'TokenId',
    prop: 'tokenId',
},{
    type: 'datetime',
    name: '创建时间',
    prop: 'createTime',
},{
    type: 'datetime',
    name: '交易时间',
    prop: 'confirmTime',
}]
export const walletWater_ = [{
    type: 'input',
    name: '变动ID',
    prop: 'changeLogId',
},
    ...fromSearchFore,
    ...contractAddress,
{
    type: 'input',
    name: '钱包名称',
    prop: 'walletName',
},{
    type: 'select',
    name: '钱包类型',
    prop: 'walletType',
    postData: walletStyle,
},{
    type: 'input',
    name: '商户地址',
    prop: 'toAddress',
},{
    type: 'input',
    name: 'TXID',
    prop: 'txid',
},{
    type: 'input',
    name: '系列名称',
    prop: 'collectionName',
},{
    type: 'input',
    name: 'NFT名称',
    prop: 'itemName',
},{
    type: 'input',
    name: 'TokenId',
    prop: 'tokenId',
}]
export const accountWater_ = [{
    type: 'input',
    name: '变动ID',
    prop: 'changeLogId',
},
    ...fromSearchFore,
    ...contractAddress,
{
    type: 'input',
    name: '系列名称',
    prop: 'collectionName',
},{
    type: 'input',
    name: 'NFT名称',
    prop: 'itemName',
},{
    type: 'input',
    name: 'TokenId',
    prop: 'tokenId',
},{
    type: 'select',
    name: '业务类型',
    prop: 'relationType',
    postData: changeType,
    props:{
        value:'value',
        label:'label'
    },
},]
export const balanceWallet_ = [
    ...fromSearchFore,
{
    type: 'input',
    name: '钱包名称',
    prop: 'walletName',
},{
    type: 'select',
    name: '钱包类型',
    prop: 'walletType',
    postData: walletStyle,
},{
    type: 'input',
    name: '钱包地址',
    prop: 'walletAddress',
},{
    type: 'datetime',
    name: '更新时间',
    prop: 'updateTime',
},]
export const balanceAccount_ = [
    ...fromSearchFore,
{
    type: 'datetime',
    name: '更新时间',
    prop: 'updateTime',
},]
export const nftSeries_ = [
    ...fromSearchFore,
    ...contractAddress,
{
    type: 'input',
    name: '系列名称',
    prop: 'collectionName',
},{
    type: 'input',
    name: '创造者地址',
    prop: 'creator',
},{
    type: 'select',
    name: '状态',
    prop: 'status',
    postData: statusType,
    props:{
        value:'value',
        label:'label'
    }
},{
    type: 'input',
    name: 'TXID',
    prop: 'txid',
},{
    type: 'datetime',
    name: '创建时间',
    prop: 'createTime',
},


]
export const nftWorks_ = [
    ...fromSearchFore,
    ...contractAddress,
{
    type: 'input',
    name: '系列名称',
    prop: 'collectionName',
},{
    type: 'input',
    name: '作品名称',
    prop: 'itemName',
},{
    type: 'input',
    name: '创造者地址',
    prop: 'mintAddr',
},{
    type: 'input',
    name: 'TXID',
    prop: 'txid',
},{
    type: 'select',
    name: '持有者类型',
    prop: 'mintorType',
    postData: [{ label: '商户', value: 2 },{label: '客户',value:1}],
    props:{
        value:'value',
        label:'label'
    }
},{
    type: 'input',
    name: 'TXID',
    prop: 'txid',
},{
    type: 'datetime',
    name: '创建时间',
    prop: 'createTime',
},
]